import axios from "axios";
import constants from './../Utils/Constants'
import '@/assets/custom.css'
import '@/assets/main.css'

import QrcodeVue from 'qrcode.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import data from './../translation/global.json'
import playStoreImage from './../assets/playStore-en.png'

export default {
    name: 'Login',
    data: function () {
        return {
            qrValue: "ABCD",
            qrValue1: "https://play.google.com/store/apps/details?id=com.ipphonecamera#",
            size: 150,
            username: null,
            selected: 0,
            password: null,
            connection: null,
            error: null,
            playStoreImg: playStoreImage,
            enterIDPass: 'Enter the ID and Password to view your Camera.',
            ippc_desc: 'Turn your mobile phone into an IP Camera',
            ippc_lang: 'Languages:',
            ippc_description: 'IP Phone Camera will allow you to view your phone camera remotely.',
            ippc_desc_1: ' IP Phone Camera will turn your phone into a IP Camera. This is a great way to utilize your old Android phones! Use any device that has a browser and an internet connection to view your mobile camera remotely.',
            ippc_desc_2: 'Get IP Phone Camera from the Google Play Store',
            username_place: 'Enter ID',
            password_place: 'Enter Password',
            btn_login: 'Login',
            playStoreLink: null,
            playStoreLinkQR: "https://play.google.com/store/apps/details?id=com.ipphonecamera&hl=en#",
            how_does_it_work: null,
            videoLink: null,
            interval: null,
            simply_scan: null,
            Launch_ippc: null,
            Launch_ippc1: null,
            enter_id_Pass: null,
            StartViewing: null,
            StartViewing1: null,
            WatchVideoT: null,
            Scan_QR: null,

            isloading: false,
            OR: null
        };
    },

    created: function () {
        this.getLang(),
            this.getuniqueId()
        if(localStorage.getItem(constants.LANGUAGE_CODE)==null){
            localStorage.setItem(constants.LANGUAGE_CODE,'0')
        }
        var myInterval = setInterval(() => {
            this.getUserData()
        }, 3000);

        setTimeout(function () {
            clearInterval(myInterval);
        }, 30000);


        if(localStorage.getItem(constants.TOKEN)){
            axios(constants.COMMANDS, {
                method: "post",
                headers: {
                    Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                    "Content-Type": "application/json",
                },
                url: constants.COMMANDS,
                data: {
                    "command": "activeClients"
                },
            })
            .then(()=>{
                this.$router.replace('/About')
            })
        }

        if (window.performance) {
            console.info("window.performance works fine on this browser");
        }
        console.info(performance.navigation.type);
        if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
            console.info("This page is reloaded");
        } else {
            console.info("This page is not reloaded");
        }
        axios.post(constants.URL + "/getapkversion", { name: "data" }).then(function (response) {
            console.log(response);
        })


    },
    components: {
        QrcodeVue
    },
    methods: {
        getuniqueId() {
            axios
                .post(constants.URL + "/uniqueId")
                .then((response) => {
                    this.isloading = true;
                    this.qrValue = response.data.message

                })
                .catch((error) => {
                    this.isloading = false;
                    console.log("qr me" + error);
                });
        },

        getUserData() {

            var getResult = {
                "uniqueId": this.qrValue
            }

            console.log(this.qrValue);
            axios
                .post(constants.URL + "/qrResult", getResult)
                .then((response) => {

                    this.username = response.data.data[0].username;
                    this.password = response.data.data[0].pass
                    this.checkLogin()
                    clearInterval(this.interval)
                })
                .catch((error) => {
                    console.log("qr code error " + error);
                    this.h = "helow";
                    console.log("ap" + this.h);
                    //     window.location.href = "https://play.google.com/store/apps/details?id=com.ipphonecamera"
                });
        },
        getLang() {
            var getLangCOde = 0
            if (localStorage.getItem(constants.LANGUAGE_CODE) != null) {
                getLangCOde = localStorage.getItem(constants.LANGUAGE_CODE)
            }
            this.selected = getLangCOde
            this.password_place = data.EnterPassword[getLangCOde],
                this.username_place = data.EnterUsername[getLangCOde],
                this.ippc_desc = data.TurnyourmobilephoneintoanIPCamera[getLangCOde],
                this.btn_login = data.Login[getLangCOde],
                this.ippc_lang = data.Languages[getLangCOde],
                this.enterIDPass = data.EnterTheIDandPasswordToViewYourCamera[getLangCOde],
                this.ippc_desc_1 = data.IPPCDescription[getLangCOde],
                this.ippc_description = data.IPPCDescription_tag[getLangCOde],
                this.ippc_desc_2 = data.GetfromtheGooglePlayStore[getLangCOde]
            this.playStoreLink = data.playStoreLink[getLangCOde]
            this.playStoreLinkQR = data.playStoreLinkQR[getLangCOde]
            this.videoLink = "https://www.deskshare.com/Tutorials/IPPC/IPPC_en.mp4",
            this.how_does_it_work = data.HowdoesitWork[getLangCOde]
            this.simply_scan = data.SimplyScan[getLangCOde]
            this.Launch_ippc = data.LaunchIPPC[getLangCOde]
            this.Launch_ippc1 = data.LaunchIPPC1[getLangCOde]
            this.enter_id_Pass = data.OREntertheIDandPasswordgiveninScreenCast[getLangCOde]
            this.StartViewing = data.StartViewing[getLangCOde]
            this.StartViewing1 = data.StartViewing1[getLangCOde]
            this.WatchVideoT = data.WatchVideoT[getLangCOde]
            this.Scan_QR = data.ScanQr[getLangCOde]

            this.OR = data.OR[getLangCOde]
            this.Home = data.Home[getLangCOde]
            this.Purchase = data.Purchase[getLangCOde]
            this.Download = data.Download[getLangCOde]
            this.Support = data.Support[getLangCOde]
            this.Resources = data.Resources[getLangCOde]
            this.AboutDeskshare = data.AboutDeskshare[getLangCOde]
            this.PrivacyPolicy = data.PrivacyPolicy[getLangCOde]
            this.ContactUs = data.ContactUs[getLangCOde]
            this.HomeLink = data.HomeLink[getLangCOde]
            this.PurchaseLink = data.PurchaseLink[getLangCOde]
            this.DownloadLink = data.DownloadLink[getLangCOde]
            this.SupportLink = data.SupportLink[getLangCOde]
            this.ResourcesLink = data.ResourcesLink[getLangCOde]
            this.AboutDeskshareLink = data.AboutDeskshareLink[getLangCOde]
            this.PrivacyPolicyLink = data.PrivacyPolicyLink[getLangCOde]
            this.ContactUsLink = data.ContactUsLink[getLangCOde]



        },



        checkLogin() {
            if(localStorage.getItem(constants.TOKEN)){
                axios(constants.COMMANDS, {
                    method: "post",
                    headers: {
                        Authorization: `${localStorage.getItem(constants.TOKEN)}`,
                        "Content-Type": "application/json",
                    },
                    url: constants.COMMANDS,
                    data: {
                        "command": "activeClients"
                    },
                })
                .then(()=>{
                    this.$router.replace('/About')
                })
            }
            var getLangCOde = localStorage.getItem(constants.LANGUAGE_CODE);
            this.login = data.Loggingin[getLangCOde];

            let ccrd = {
                username: this.username,
                password: this.password,
                language: localStorage.getItem(constants.LANGUAGE_CODE) == null ? '0' : localStorage.getItem(constants.LANGUAGE_CODE),
                version: "new",
            };
            if (this.username == null || this.password == null) {
                this.error = data.EnterUsernameAndPassword[getLangCOde]
            }
            if (this.username != null && this.password != null) {
                axios
                    .post(constants.URL + '/login', ccrd)
                    .then((response) => {
                        console.log("myLOg" + response.status);
                        if (response.status == 200) {
                            localStorage.token = response.data.jwt
                            console.log("sfsd" + localStorage.token)
                            if (localStorage.getItem(constants.LANGUAGE_CODE) == null) {
                                localStorage.setItem(constants.LANGUAGE_CODE, 0)
                            }
                            this.$router.replace('/About')
                        } else if (response.status == 400) {
                            this.error == data.InvalidusernameorIPPhoneCameranotconnected[localStorage.getItem(constants.LANGUAGE_CODE)]
                        }
                    })
                    .catch((error) => {
                        this.errorHandling(error)
                    });
            } else {
                this.error = data.EnterUsernameAndPassword[getLangCOde]
            }
        },

        errorHandling(errorName) {
            var getLangCOde = localStorage.getItem(constants.LANGUAGE_CODE)
            if (JSON.stringify(errorName).includes("401")) {
                this.error = data.Invalidpassword[getLangCOde]
            } else if (errorName == "Invalid username or IP Phone Camera not connected.") {
                this.error = data.InvalidusernameorIPPhoneCameranotconnected[getLangCOde]
            } else if (errorName == "Network not available. Please check your Internet connection and try again") {
                this.error = data.NetworkError[getLangCOde]
            } else if (errorName == "Service temporarily unavailable. Please try later") {
                this.error = data.Internalservererror[getLangCOde]
            } else {
                this.error = data.IPPCNotConnect[getLangCOde]
            }
        },

        handleChange(e) {

            console.log(e.target.value)
            localStorage.setItem(constants.LANGUAGE_CODE, e.target.value)
            this.getLang()
            this.error = null
        },
    },
};