<template>
    <div class="app loginapp">
        <div class="Background" style="min-height:auto">
            <div class="well top"
                 style="
            background: #00589d;

            padding-top: 0.6% !important;
            padding-bottom: 0.4% !important;

          ">
                <a style="margin-top:5px;" class="logo-a">
                    <img class="inline vertically-middle logo-img"
                         src="./../assets/logo.png"
                         style="max-width: calc(4vw + 0.1vh + 2vmin);margin-top:2px;margin-bottom:2px;" />
                </a>
                <div class="inline-table vertically-super LeftPadding10" style="padding-bottom:3px;">
                    <p class="margin-zero title-heading" style="font-size:calc(1vw + 0.1vh + 2vmin);padding-top:0%;padding-bottom:0%;font-weight:600;margin-top:-4px!important">IP Phone Camera</p>
                    <p class="title-sub-heading" style="font-size:calc(1vw + 0.1vh + 1vmin);padding-bottom:1px;margin:0%;margin-top:-4px">{{ ippc_desc }}</p>
                </div>
                <div style="float: right; padding-top: 15px" class="paddingRight localization-opendiv">
                    <div class="localization-dropdown-wrapper" style="z-index: 999">
                        <span class="language-label-root" style="color: rgb(255, 255, 255);font-size:calc(1vw + 0.1vh + 1vmin)">
                            {{ ippc_lang }}
                        </span>
                        <select class="language-select-dropdown-root"
                                style="color: black; margin-right: 1vw;width:calc(9vw + 0.1vh + 2vmin) ;border:1px solid black"
                                v-model="selected"
                                @change="handleChange($event)">
                            <option value="0" style="color: black" selected>English</option>
                            <option value="1" style="color: black">Deutsch</option>
                            <option value="2" style="color: black">Français</option>
                            <option value="3" style="color: black">Español</option>
                            <option value="4" style="color: black">Português</option>
                            <option value="5" style="color: black">Italiano</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row_login">
                <div class="column_login col1" style="width:55%;padding-left:calc(5.5vw + 0.1vh + 2vmin)">
                    <p class="normal textheader" style="line-height:2.1vw">{{ippc_description}}</p>
                    <div style="margin-top: 5px"></div>
                    <span class="bold textsubheader1">{{how_does_it_work}}</span>
                    <div style="margin-top: 5px"></div>
                    <table class="tableClass">
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <td class="bullet"></td>
                            <td class="datalist">
                                {{ Launch_ippc }}
                                <a :href="playStoreLink"
                                   target="_blank">IP Phone Camera</a> {{ Launch_ippc1 }}
                            </td>
                        </tr>
                        <tr>
                            <td class="bullet"></td>
                            <td class="datalist">{{ simply_scan }}</td>
                        </tr>
                        <tr style="padding-top: 20px">
                            <td class="bullet"></td>
                            <td class="datalist">{{ StartViewing }}</td>
                        </tr>
                        <tr>
                            <td class="bullet"></td>
                            <td class="datalist">{{ StartViewing1 }}</td>
                        </tr>
                        <tr></tr>
                    </table>

                    <div style="margin-top: 1.5vw"></div>
                    <span class="">
                        <a :href="videoLink" target="_blank" class="watchTutoriallink" style="font-size:font-size:calc(1.4vw + 0.1vh + 0.4vmin)">
                            {{ WatchVideoT }}
                        </a>
                    </span>
                </div>
                <div class="column_login col2" style="width: 45%;">
                    <div class="login-panel_bar" style="margin:0 30px 0 80px">
                        <center>
                            <p class="normal scanQrText scanQrTextdisplay1 " v-show="isloading" style="color: #00589d;width:15vw">
                                {{ Scan_QR }}
                            </p>

                        </center>
                        <center>
                            <qrcode-vue :value="playStoreLinkQR + qrValue"
                                        :size="size"
                                        style="width:9vw;height:auto"
                                        level="H"
                                        v-show="isloading" />
                        </center>
                        <p class="normal scanQrText" v-show="isloading" style="color: #00589d;margin-bottom:0px">
                            <center>{{ OR }}</center>
                        </p>
                        <p class="normal scanQrText textEnterUserpass" style="color: #00589d" id="textEnterUserpass">
                            <center>{{ enter_id_Pass }}</center>
                        </p>
                        <div class="">
                            <center>
                                <input v-model="username"
                                       type="text"
                                       name="user_username"
                                       id="user_username"
                                       class="LoginTbx"
                                       :placeholder="username_place"
                                       style="
                      margin-bottom: 10px;
                      border-radius: 6px;
                      padding-start: 5px;
                    " />
                            </center>

                            <center>
                                <input v-model="password"
                                       type="password"
                                       name="user_password"
                                       id="user_password"
                                       class="LoginTbx"
                                       :placeholder="password_place"
                                       style="border-radius: 6px; padding-start: 5px" />
                            </center>
                            <br />
                            <div class="">
                                <center>
                                    <button type="submit"
                                            @click="checkLogin()"
                                            class="LoginButton">
                                        {{ btn_login }}
                                    </button>
                                </center>
                            </div>
                            <div style="color: red">
                                <center>
                                    <span class="errorText">{{ error }}</span>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div class="foto"
             style="
         padding-top:0% !important;
          border-top: 1px solid #c0c0c0;

         margin-top:15px;
          width: 100%;
          margin-bottom: 0% !important;
           position: absolute;
       bottom:5px;
        ">
            <div align="center" style="padding: 1%;margin-bottom: 0% !important;padding-bottom:0% !important">
                <ul style="
              margin: auto;
              margin-left: -40px;
              padding: auto;
              overflow: hidden;
              list-style-type: none;
            "
                    class="fotoUllist">
                    <li class="foot-list" style="margin-left:1.2%">
                        <a class="foot-a" :href=HomeLink target="_blank">{{ Home }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=PurchaseLink target="_blank">{{ Purchase }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=DownloadLink target="_blank">{{ Download }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=SupportLink target="_blank">{{ Support }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=ResourcesLink target="_blank">{{ Resources }}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=AboutDeskshareLink target="_blank">{{AboutDeskshare}}&nbsp;DeskShare</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a"
                           :href=PrivacyPolicyLink target="_blank">{{PrivacyPolicy}}</a>&nbsp;|&nbsp;
                    </li>

                    <li class="foot-list">
                        <a class="foot-a" :href=ContactUsLink target="_blank">{{ContactUs}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script src="./../js/home.js"></script>